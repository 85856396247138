













import { Component, Prop, Vue } from 'vue-property-decorator'
import PageHeader from '@/components/PageHeader.vue'
import Table from '@/components/data-table/Table.vue'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import capitalize from '@/shared/helpers/capitalize'
import { CalculationLogType } from '@/shared/configs/calculation-log-types'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import Log from '@/shared/modules/log/log'
import http from '@/shared/http'

@Component({
  components: { Table, PageHeader }
})
export default class LogShow extends Vue {
  @Prop() meta!: any

  log: Log | null = null
  table: DataTableBase = new DataTableBase()
    .setHeaders([
      new DataTableHeader()
        .setKey('calculator.name')
        .setTitle('Calculator'),
      new DataTableHeader()
        .setKey('start')
        .setTitle('Start date'),
      new DataTableHeader()
        .setKey('user.name')
        .setTitle('User'),
      new DataTableHeader()
        .setKey('status')
        .setTitle('Status')
        .setValueParser(capitalize)
        .setStyleParser((value: string) => {
          let background, color

          switch (value) {
            case CalculationLogType.success:
              background = 'rgba(54, 179, 83, 0.4)'
              color = 'rgba(54, 179, 83, 1)'
              break
            case CalculationLogType.failure:
              background = 'rgba(255, 128, 139, 0.4)'
              color = 'rgba(255, 128, 139, 1)'
              break
          }

          return {
            container: {
              background,
              color,
              borderRadius: '4px',
              padding: '4px 15px',
            }
          }
        }),
      new DataTableHeader()
        .setKey('testing')
        .setTitle('Testing')
        .setValueParser((value: boolean) => value ? capitalize(value.toString()) : '')
        .setStyleParser((value: boolean) => {
          let background, color

          switch (value) {
            case true:
              background = 'rgba(94, 129, 244, 0.4)'
              color = this.$vuetify.theme.themes.light.secondary
              break
            case false:
              return {}
          }

          return {
            container: {
              background,
              color,
              borderRadius: '4px',
              padding: '4px 15px',
            }
          }
        }),
      new DataTableHeader()
        .setKey('duration')
        .setTitle('Duration'),
    ])
    .setPagination(false)

  async created() {
    this.log = await http.get(`clients/${ this.selectedClient.uuid }/calculators/${ this.meta.log.calculator.reference }/logs/${ this.meta.log.uuid }`)
      .then((response: any) => response.data)

    this.table.setData([ this.log ])
  }

  get tableRef(): any {
    return this.$refs.table
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
