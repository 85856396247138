import SelectItem from '@/shared/classes/form/fields/select/select-item'

export enum CalculationLogType {
  success = 'success',
  failure = 'failure',
}

export const calculationLogTypes: SelectItem[] = [
  new SelectItem().setValue(CalculationLogType.success).setTitle('Success'),
  new SelectItem().setValue(CalculationLogType.failure).setTitle('Failure'),
]
