






































import { Component, Vue, Watch } from 'vue-property-decorator'
import DataTableBase from '@/shared/classes/data-table/data-table-base'
import DataTableHeader from '@/shared/classes/data-table/data-table-header'
import User from '@/shared/modules/user/user'
import { AuthGetters } from '@/shared/store/auth/auth.getters'
import Client from '@/shared/modules/client/client'
import DataTable from '@/components/data-table/DataTable.vue'
import Tabs from '@/components/tabs/Tabs.vue'
import Tab from '@/shared/classes/tabs/tab'
import { Routes } from '@/shared/router'
import DataTableDefaultFilterInterface from '@/shared/interfaces/data-table-default-filter.interface'
import { CalculationLogType } from '@/shared/configs/calculation-log-types'
import capitalize from '@/shared/helpers/capitalize'
import { FilterOperator } from '@/shared/classes/data-table/data-table-filter'
import { getQueryName } from '@/shared/helpers/query.helper'
import { GlobalActions } from '@/shared/store/global/global.actions'
import LogShow from '@/components/logs/LogShow.vue'
import Log from '@/shared/modules/log/log'
import ShowAction from '@/components/data-table/default-actions/show-action'
import _ from 'lodash'

@Component({
  components: { Tabs, DataTable }
})
export default class Logs extends Vue {
  mounted: boolean = false

  @Watch('$route.path', { immediate: true }) private handleRouteFilterChanges() {
    const filter: DataTableDefaultFilterInterface | null = this.$router.currentRoute.meta.filter

    this.range = []
    const query: any = this.$router.currentRoute.query

    Object.keys(query).map((key: any) => {
      if (key.startsWith('filter')) this.range.push(_.get(query, key))
    })

    if (! this.mounted) {
      this.table = new DataTableBase().setDefaultFilters(filter ? [ filter ] : [])

      this.mounted = true
    }

    this.table?.setDefaultFilters(filter ? [ filter ] : [])
  }

  tabs: Tab[] = [
    new Tab()
      .setTitle('All logs')
      .setName(Routes.profileCalculationLogsAll),
    new Tab()
      .setTitle('Success')
      .setName(Routes.profileCalculationLogsSuccess),
    new Tab()
      .setTitle('Failure')
      .setName(Routes.profileCalculationLogsFailure),
  ]

  menu: boolean = false
  range: string[] = []
  table: DataTableBase | null = null

  created() {
    if (! this.selectedClient) return

    this.table && this.table
      .setEndpoint(`clients/${ this.selectedClient.uuid }/calculators/logs/all`)
      .setHeaders([
        new DataTableHeader()
          .setKey('calculator.name')
          .setTitle('Calculator'),
        new DataTableHeader()
          .setKey('start')
          .setTitle('Start date'),
        new DataTableHeader()
          .setKey('user.name')
          .setTitle('User'),
        new DataTableHeader()
          .setKey('status')
          .setTitle('Status')
          .setValueParser(capitalize)
          .setClasses('status-badge')
          .setStyleParser((value: string) => {
            let background, color

            switch (value) {
              case CalculationLogType.success:
                background = 'rgba(54, 179, 83, 0.4)'
                color = 'rgba(54, 179, 83, 1)'
                break
              case CalculationLogType.failure:
                background = 'rgba(255, 128, 139, 0.4)'
                color = 'rgba(255, 128, 139, 1)'
                break
            }

            return {
              container: {
                background,
                color,
                borderRadius: '4px',
                padding: '4px 15px',
              }
            }
          }),
        new DataTableHeader()
          .setKey('duration')
          .setTitle('Duration'),
      ])
    .setActions([
      new ShowAction().setAction(this.openViewDialog)
    ])
  }

  openViewDialog(log: Log) {
    this.$store.dispatch(GlobalActions.showDialog, {
      maxWidth: 1200,
      component: LogShow,
      meta: {
        log,
      }
    })
  }

  updateDatePickerAllowedDate(dates: string[]) {
    if (dates.length === 2) {
      this.range = dates[0] > dates[1] ? [dates[1]] : dates
    }

    if (this.range.length === 2) this.menu = false
  }

  filterByDate() {
    if (this.range.length === 0) return

    let { query }: any = this.$router.currentRoute
    const { name }: any = this.$router.currentRoute
    const { defaultFilters }: any = this.table
    query = { ...query }

    let filters: DataTableDefaultFilterInterface[] = [
      ...defaultFilters,
      {
          operator: FilterOperator.gte,
          key: 'created_at',
          value: this.range[0],
      }
    ]

    if (this.range.length === 2) {
      filters = [...filters, {
        operator: FilterOperator.lte,
        key: 'created_at',
        value: this.range[1],
      }]
    }

    this.table?.setDefaultFilters(filters)

    const searchParams = new URLSearchParams(query)

    filters.map((filter: DataTableDefaultFilterInterface) => {
      searchParams.set(getQueryName(filter.operator, filter.key), filter.value)
    })

    searchParams.forEach((value: string, key: string) => query[key] = value)
    this.$router.push({ name, query }).catch(() => {})
  }

  get user(): User {
    return this.$store.getters[AuthGetters.getUser]
  }

  get selectedClient(): Client {
    return this.user.selected_client || this.user.clients[0]
  }
}
